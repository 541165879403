.menu-nav .rs-nav-item > .rs-nav-item-content {
  color:white;
  font-weight: bold;
}
.menu-nav .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:hover, 
.menu-nav .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:focus {
  color: #d5d1d1;
  font-weight: bold;
}

.service{
  height: 285px;
}

.projects{
  height: 250px;
}

.title-service .rs-panel-heading{
  text-align: center;
  padding:10px;
}

.title-footer,
.content-footer{
  color: #fff;
  font-weight: bold;
}

.title-footer{
  text-align: center;
  padding:10px;
}
.title-footer div{
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 100%;
}

.error{
  box-shadow: 0 0 5px 1px red !important;
}
